// src/utils/fetchMetadata.js
import axios from 'axios';

const FAVICON_API = 'https://www.google.com/s2/favicons?domain=';
const CORS_PROXY = 'https://api.allorigins.win/raw?url=';

/**
 * Extracts domain from URL
 */
const getDomain = (url) => {
  try {
    const domain = new URL(url);
    return domain.hostname;
  } catch (e) {
    return url;
  }
};

/**
 * Extracts OpenGraph image from HTML
 */
const extractOGImage = (html) => {
  try {
    const ogImageMatch = html.match(/<meta[^>]*property="og:image"[^>]*content="([^"]*)"[^>]*>/i) ||
                        html.match(/<meta[^>]*content="([^"]*)"[^>]*property="og:image"[^>]*>/i);
    
    if (ogImageMatch && ogImageMatch[1]) {
      return ogImageMatch[1];
    }

    // Fallback to twitter image
    const twitterImageMatch = html.match(/<meta[^>]*name="twitter:image"[^>]*content="([^"]*)"[^>]*>/i) ||
                             html.match(/<meta[^>]*content="([^"]*)"[^>]*name="twitter:image"[^>]*>/i);
    
    return twitterImageMatch ? twitterImageMatch[1] : null;
  } catch (e) {
    console.error('Error extracting OG image:', e);
    return null;
  }
};

/**
 * Extracts title from HTML
 */
const extractTitle = (html) => {
  try {
    const ogTitleMatch = html.match(/<meta[^>]*property="og:title"[^>]*content="([^"]*)"[^>]*>/i) ||
                        html.match(/<meta[^>]*content="([^"]*)"[^>]*property="og:title"[^>]*>/i);
    
    if (ogTitleMatch && ogTitleMatch[1]) {
      return ogTitleMatch[1];
    }

    const titleMatch = html.match(/<title[^>]*>([^<]*)<\/title>/i);
    return titleMatch ? titleMatch[1] : null;
  } catch (e) {
    console.error('Error extracting title:', e);
    return null;
  }
};

/**
 * Extracts description from HTML
 */
const extractDescription = (html) => {
  try {
    const ogDescMatch = html.match(/<meta[^>]*property="og:description"[^>]*content="([^"]*)"[^>]*>/i) ||
                       html.match(/<meta[^>]*content="([^"]*)"[^>]*property="og:description"[^>]*>/i);
    
    if (ogDescMatch && ogDescMatch[1]) {
      return ogDescMatch[1];
    }

    const metaDescMatch = html.match(/<meta[^>]*name="description"[^>]*content="([^"]*)"[^>]*>/i) ||
                         html.match(/<meta[^>]*content="([^"]*)"[^>]*name="description"[^>]*>/i);
    
    return metaDescMatch ? metaDescMatch[1] : null;
  } catch (e) {
    console.error('Error extracting description:', e);
    return null;
  }
};

/**
 * Fetches metadata from URL with retries
 */
const fetchUrlMetadata = async (url, retryCount = 2) => {
  for (let i = 0; i < retryCount; i++) {
    try {
      const response = await axios.get(`${CORS_PROXY}${encodeURIComponent(url)}`, {
        headers: {
          'Accept': 'text/html',
          'x-requested-with': 'XMLHttpRequest'
        },
        timeout: 5000 // 5 second timeout
      });

      if (!response.data) {
        throw new Error('No data received');
      }

      const html = response.data;
      return {
        image: extractOGImage(html),
        title: extractTitle(html),
        description: extractDescription(html)
      };
    } catch (error) {
      console.error(`Attempt ${i + 1} failed:`, error);
      if (i === retryCount - 1) {
        return null;
      }
      // Wait for a short time before retrying
      await new Promise(resolve => setTimeout(resolve, 1000));
    }
  }
  return null;
};

/**
 * Creates a preview for a link
 */
const createLinkPreview = async (linkData) => {
  try {
    const domain = getDomain(linkData.contentUrl);
    let metadata = null;

    // Only try to fetch metadata if it's a valid URL
    if (linkData.contentUrl.startsWith('http')) {
      metadata = await fetchUrlMetadata(linkData.contentUrl);
    }

    return {
      url: linkData.contentUrl,
      title: metadata?.title || linkData.contentTitle || domain,
      description: metadata?.description || linkData.contentDescription || '',
      image: metadata?.image || linkData.contentImage || `${FAVICON_API}${domain}`,
      favicon: `${FAVICON_API}${domain}`,
      domain: domain,
      usesFavicon: !metadata?.image && !linkData.contentImage
    };
  } catch (error) {
    console.error('Error creating link preview:', error);
    return null;
  }
};

/**
 * Gets preview data for multiple URLs
 */
export async function fetchMetadata(links) {
  if (!Array.isArray(links)) return [];
  
  const previews = await Promise.all(
    links.map(async (link) => {
      if (!link?.contentUrl) return null;
      return await createLinkPreview(link);
    })
  );
  
  return previews.filter(Boolean);
}







