function vCardEncode(value) {
  if (!value) return '';
  return value
    .replace(/\\/g, '\\\\')
    .replace(/;/g, '\\;')
    .replace(/,/g, '\\,')
    .replace(/\n/g, '\\n');
}

function nameToVCard(name) {
  if (!name) return [];
  
  const lines = [];
  const components = [
    name.last || '',
    name.first || '',
    name.middle || '',
    name.prefix || '',
    name.suffix || '',
  ];
  
  // Add formatted name (FN) - required in vCard 4.0
  const formattedName = [name.prefix, name.first, name.middle, name.last, name.suffix]
    .filter(Boolean)
    .join(' ')
    .trim();
  if (formattedName) {
    lines.push(`FN:${vCardEncode(formattedName)}`);
  }
  
  // Add structured name (N)
  lines.push(`N:${components.map(vCardEncode).join(';')}`);
  
  return lines;
}

function phoneToVCard(phone) {
  if (!phone?.number) return [];
  
  const v4 = false;
  let s = v4 ? "TEL;VALUE=uri" : "TEL";
  const types = new Set();

  // Handle phone types
  switch (phone.label?.toLowerCase()) {
    case "home": types.add("home"); break;
    case "work": types.add("work"); break;
    case "faxwork": types.add("fax"); types.add("work"); break;
    case "faxhome": types.add("fax"); types.add("home"); break;
    case "mobile": types.add("cell"); types.add(v4 ? "text" : "voice"); break;
    case "main": types.add("main"); break;
    case "other": types.add("voice"); break;
  }

  if (!v4 && phone.isPrimary) {
    types.add("pref");
  }

  // Add type parameters
  const typeArray = [...types];
  if (typeArray.length === 1) {
    s += `;TYPE=${typeArray[0]}`;
  } else if (typeArray.length > 1) {
    s += v4 ? `;TYPE="${typeArray.join(',')}"` : `;TYPE=${typeArray.join(',')}`;
  }

  if (v4 && phone.isPrimary) {
    s += ";PREF=1";
  }

  // Add phone number
  s += v4 ? `:tel:${vCardEncode(phone.number)}` : `:${vCardEncode(phone.number)}`;
  return [s];
}

function emailToVCard(email) {
  if (!email?.address) return [];
  
  const v4 = false;
  let s = "EMAIL";
  const types = new Set();

  // Handle email types
  switch (email.label?.toLowerCase()) {
    case "home": types.add("home"); break;
    case "work": types.add("work"); break;
    default: types.add("internet");
  }

  if (email.isPrimary) {
    v4 ? s += ";PREF=1" : types.add("pref");
  }

  // Add type parameters
  const typeArray = [...types];
  if (typeArray.length > 0) {
    s += `;TYPE=${typeArray.join(',')}`;
  }

  s += `:${vCardEncode(email.address)}`;
  return [s];
}

function addressToVCard(address) {
  if (!address) return [];
  
  const v4 = false;
  let s = "ADR";
  const types = new Set();

  // Handle address types
  switch (address.label?.toLowerCase()) {
    case "home": types.add("home"); break;
    case "work": types.add("work"); break;
    case "other": types.add("other"); break;
  }

  // Add type parameters
  const typeArray = [...types];
  if (typeArray.length > 0) {
    s += `;TYPE=${typeArray.join(',')}`;
  }

  // Handle structured vs unstructured addresses
  if (address.street || address.city || address.state || address.postalCode) {
    s += `:${[
      vCardEncode(address.pobox || ''),
      '', // Extended address
      vCardEncode(address.street || ''),
      vCardEncode(address.city || ''),
      vCardEncode(address.state || ''),
      vCardEncode(address.postalCode || ''),
      vCardEncode(address.country || '')
    ].join(';')}`;
  } else if (address.address) {
    s += `:;;${vCardEncode(address.address)};;;;`;
  }

  return [s];
}

function organizationToVCard(organization) {
  if (!organization) return [];
  
  const lines = [];
  if (organization.company) {
    lines.push(`ORG:${vCardEncode(organization.company)}`);
  }
  if (organization.title) {
    lines.push(`TITLE:${vCardEncode(organization.title)}`);
  }
  if (organization.department) {
    lines.push(`ORG:${vCardEncode(organization.company)};${vCardEncode(organization.department)}`);
  }
  return lines;
}

function websiteToVCard(website) {
  if (!website?.url) return [];
  return [`URL${website.label ? `;TYPE=${website.label.toLowerCase()}` : ''}:${vCardEncode(website.url)}`];
}

function socialMediaToVCard(socialMedia) {
  if (!socialMedia?.userName) return [];
  
  const protocol = socialMedia.label === "custom" && socialMedia.customLabel
    ? socialMedia.customLabel
    : socialMedia.label;
    
  if (!protocol) return [];
  
  return [`X-SOCIALPROFILE;TYPE=${vCardEncode(protocol)}:${vCardEncode(socialMedia.userName)}`];
}

export async function fetchPhotoAsBase64(url) {
  if (!url) return null;
  
  try {
    const response = await fetch(url);
    if (!response.ok) throw new Error(`HTTP error! status: ${response.status}`);
    
    const blob = await response.blob();
    if (!blob.type.startsWith('image/')) {
      throw new Error('URL does not point to an image');
    }
    
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onloadend = () => {
        const base64Data = reader.result.split(',')[1];
        resolve(base64Data);
      };
      reader.onerror = () => reject(new Error('Failed to read image data'));
      reader.readAsDataURL(blob);
    });
  } catch (error) {
    console.error('Error fetching photo:', error);
    return null;
  }
}

export function toVCard(
  mySmartcard,
  myphotoByte,
  { withPhoto = false, productId = null, includeDate = false, encoding = 'base64' } = {}
) {
  if (!mySmartcard) {
    throw new Error('Contact information is required');
  }

  // Force vCard 3.0 for maximum compatibility
  const v4 = false;
  const lines = [
    "BEGIN:VCARD",
    "VERSION:3.0",  // Version 3.0 has the best cross-device compatibility
    "PROFILE:VCARD"
  ];

  try {
    // Add optional fields
    if (productId) {
      lines.push(`PRODID:${vCardEncode(productId)}`);
    }
    
    if (includeDate) {
      lines.push(`REV:${new Date().toISOString().replace(/[-:]/g, '').split('.')[0]}Z`);
    }

    // Add name fields - ensure FN is always present (required by spec)
    if (mySmartcard.name) {
      const nameLines = nameToVCard(mySmartcard.name);
      // Ensure FN exists as it's required for iOS
      if (!nameLines.some(line => line.startsWith('FN:'))) {
        const fallbackName = mySmartcard.name.first || mySmartcard.name.last || 'Unknown';
        lines.push(`FN:${vCardEncode(fallbackName)}`);
      }
      lines.push(...nameLines);
    }

    // Add photo if available - with iOS-specific formatting
    if (withPhoto && myphotoByte) {
      // Clean up the photo data
      let photoData = myphotoByte;
      if (photoData.includes('base64,')) {
        photoData = photoData.split('base64,')[1];
      }
      
      // Remove any whitespace or newlines from base64
      photoData = photoData.replace(/[\r\n\s]/g, '');

      // Detect image type from base64 header
      const isJPEG = photoData.startsWith('/9j/');
      const isPNG = photoData.startsWith('iVBOR');
      
      // For iOS compatibility:
      // 1. Use JPEG;ENCODING=b format
      // 2. Split long base64 strings into 75-character chunks
      // 3. Add proper line folding for iOS
      const photoType = isJPEG ? 'JPEG' : (isPNG ? 'PNG' : 'JPEG');
      const photoLine = `PHOTO;ENCODING=b;TYPE=${photoType}:${photoData}`;
      
      // Split into chunks of 75 characters for iOS compatibility
      const chunks = photoLine.match(/.{1,75}/g) || [];
      lines.push(chunks[0]); // First line
      chunks.slice(1).forEach(chunk => {
        lines.push(` ${chunk}`); // Continuation lines start with a space
      });
    }

    // Add contact fields if they exist - ensure proper mobile formatting
    if (Array.isArray(mySmartcard.phones)) {
      const phones = mySmartcard.phones
        .map(phone => {
          // Normalize phone numbers for better compatibility
          const normalizedNumber = phone.number.replace(/[^\d+]/g, '');
          return { ...phone, number: normalizedNumber };
        })
        .map(phoneToVCard)
        .flat();
      lines.push(...phones);
    }
    
    if (Array.isArray(mySmartcard.emails)) {
      lines.push(...mySmartcard.emails.map(emailToVCard).flat());
    }
    
    if (Array.isArray(mySmartcard.addresses)) {
      lines.push(...mySmartcard.addresses.map(addressToVCard).flat());
    }
    
    if (Array.isArray(mySmartcard.organizations)) {
      const orgs = mySmartcard.organizations.map(organizationToVCard).flat();
      // Ensure only one ORG field for better iOS compatibility
      const filteredOrgs = orgs.filter((org, index, array) => 
        org.startsWith('ORG:') ? array.findIndex(o => o.startsWith('ORG:')) === index : true
      );
      lines.push(...filteredOrgs);
    }
    
    if (Array.isArray(mySmartcard.websites)) {
      lines.push(...mySmartcard.websites.map(websiteToVCard).flat());
    }
    
    if (Array.isArray(mySmartcard.socialMedias)) {
      lines.push(...mySmartcard.socialMedias.map(socialMediaToVCard).flat());
    }

    lines.push("END:VCARD");

    // Ensure proper line endings for all platforms
    // Use \r\n for maximum compatibility
    return lines.join("\r\n") + "\r\n";
  } catch (error) {
    console.error('Error generating vCard:', error);
    throw new Error('Failed to generate vCard: ' + error.message);
  }
}
