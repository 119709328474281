// Firebase Collections
export const FIREBASE_COLLECTIONS = {
  //SMART_CARDS: 'testqrdocuments',
  SMART_CARDS: 'contactqrs',
  //CONTACTS: 'contacts',
  // Add other collections here as needed
};

// Firebase Subcollections
export const FIREBASE_SUBCOLLECTIONS = {
  RECEIVED_CONTACTS: 'receivedContacts',
  // Add other subcollections here as needed
};

// Storage Paths
export const STORAGE_PATHS = {
  USER_IMAGES: 'userImages',
  // Add other storage paths here as needed
};

// Firebase Config
export const FIREBASE_CONFIG = {
  apiKey: "AIzaSyAMSxJQpBgJ01Mb_-IbYkjfB6BTKZcbprQ",
  authDomain: "smart-card-c4f01.firebaseapp.com",
  databaseURL: "https://smart-card-c4f01.firebaseio.com",
  projectId: "smart-card-c4f01",
  storageBucket: "smart-card-c4f01.appspot.com",
  messagingSenderId: "813539534721",
  appId: "1:813539534721:web:8e1e960fa66a84ea1caa80",
  measurementId: "G-FHRGTWW1CY"
}; 