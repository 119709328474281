<template>
  <!-- Profile Section -->

  <div class="profile-card" v-if="mysmartcard">
    <div class="profile-header">
      <img 
      :src="displayPhotoUrl" 
      :alt="altText"
      class="profile-img" 
      loading="lazy"
      @error="handleImageError"
      :class="{ 'error': imageLoadError }" 
    />

    </div>
    <div class="profile-body">
      <h2> {{ fullName }} </h2>
      <template v-if="hasOrganization">
        <p class="profession">{{ mysmartcard.organizations[0].title }}</p>
        <p class="profession">{{ mysmartcard.organizations[0].company }}</p>

      </template>

      <div class="social-icons" v-if="hasSocialMedia">
        <template v-for="(link, index) in socialLinks" :key="index">
          <a v-bind:href="link.url" target="_blank">
            <Icon :icon="link.icon" width="16" height="16" />
          </a>
        </template>

      </div>

      <div class="profile-footer">
        <div class="button-group">
          <button @click="handleDownload" class="btn" :disabled="isDownloading">
            <Icon :icon="isDownloading ? 'line-md:loading-twotone-loop' : 'line-md:downloading-loop'" 
                  width="24" height="24" style="color: black" />
            <span class="pl-1">{{ downloadButtonText }}</span>
          </button>
          
          <button @click="toggleQR" class="btn qr-btn">
            <Icon icon="mdi:qrcode" width="24" height="24" />
            <span class="pl-1">Scan QR</span>
          </button>
        </div>

        <!-- QR Code Modal -->
        <div v-if="showQR" class="qr-modal" @click.self="toggleQR">
          <div class="qr-modal-content">
            <button class="close-btn" @click="toggleQR" aria-label="Close">
              <Icon icon="mdi:close" width="24" height="24" />
            </button>
            <h3>Scan with your phone</h3>
            <img :src="'https://api.qrserver.com/v1/create-qr-code/?size=200x200&data=' + encodeURIComponent(currentUrl)" 
                 alt="QR Code"
                 class="qr-code" />
            <p class="qr-instructions">Scan this QR code with your phone's camera to open this page</p>
          </div>
        </div>
      </div>
    </div>

    <!-- Add the bottom sheet component -->
    <ContactBottomSheet
      :show="showContactSheet"
      :recipient-name="fullName"
      :card-share-id="mysmartcard._AppShare.cardIdShare"
      :fcm-token="mysmartcard._AppShare?.fcmToken"
      @close="showContactSheet = false"
      @submit-success="handleContactSubmitSuccess"
      @submit-error="handleContactSubmitError"
    />
  </div>
</template>

<script>
//import {saveAs} from 'file-saver';
import { fetchPhotoAsBase64, toVCard } from "@/composables/getvCard";
import { Icon } from "@iconify/vue";
import defaultImage from '@/assets/images/abc.webp';
import { ref, computed, watchEffect, onUnmounted, onMounted } from "vue";
import {
  getStorage,
  ref as storageRef,
  getDownloadURL,
} from "firebase/storage";
import ContactBottomSheet from '@/components/ContactBottomSheet.vue';

// Move constants outside component to prevent recreation
const storage = getStorage();
const SOCIAL_MEDIA_CONFIG = {
  x: { icon: 'simple-icons:x' },
  facebook: { icon: 'simple-icons:facebook' },
  linkedIn: { icon: 'simple-icons:linkedin' },
  custom: { icon: 'mdi:external-link' }
};

// Global photo URL cache
const globalPhotoUrlCache = new Map();

export default {
  name: "ProfileCard",
  props: {
    mysmartcard: {
      type: Object,
      required: true, // Fixed capitalization
    },
  },
  components: {
    Icon,
    ContactBottomSheet
  },
  setup(props) {
    const myphotoByte = ref(null);
    const isDownloading = ref(false);
    const imageLoadError = ref(false);
    const myphotoUrl = ref(defaultImage);
    const showQR = ref(false);
    const currentUrl = ref('');
    const showContactSheet = ref(false);

    // Memoized photo fetching
    const fetchPhotoUrl = async (photoUrl) => {
      if (!photoUrl) return defaultImage;
      
      if (globalPhotoUrlCache.has(photoUrl)) {
        return globalPhotoUrlCache.get(photoUrl);
      }

      try {
        const imageRef = storageRef(storage, `userImages/${photoUrl}`);
        const url = await getDownloadURL(imageRef);
        globalPhotoUrlCache.set(photoUrl, url);
        return url;
      } catch (error) {
        console.error("Error fetching image URL:", error);
        return defaultImage;
      }
    };

    // Optimized photo loading with cleanup
    const loadPhoto = async () => {
      if (!props.mysmartcard?.photoUrl) return;
      
      try {
        const url = await fetchPhotoUrl(props.mysmartcard.photoUrl);
        if (url !== defaultImage) {
          const photoData = await fetchPhotoAsBase64(url);
          myphotoUrl.value = photoData;
        }
      } catch (error) {
        console.error("Error loading photo:", error);
        myphotoUrl.value = defaultImage;
      }
    };

    // Watch for photo URL changes
    watchEffect(() => {
      if (props.mysmartcard?.photoUrl) {
        loadPhoto();
      }
    });

    // Optimized computed properties
    const displayPhotoUrl = computed(() => {
      try {
        if (!myphotoUrl.value || imageLoadError.value) return defaultImage;
        
        if (myphotoUrl.value.startsWith('data:image/')) return myphotoUrl.value;
        if (myphotoUrl.value.match(/^[A-Za-z0-9+/=]+$/)) return `data:image/jpeg;base64,${myphotoUrl.value}`;
        if (myphotoUrl.value.startsWith('http')) return myphotoUrl.value;
        
        return defaultImage;
      } catch {
        return defaultImage;
      }
    });

    const fullName = computed(() => {
      const { first = '', last = '' } = props.mysmartcard?.name || {};
      return `${first} ${last}`.trim();
    });

    const altText = computed(() => 
      `${fullName.value}'s Profile Picture` || 'Profile Picture'
    );

    const hasOrganization = computed(() => 
      props.mysmartcard?.organizations?.length > 0
    );

    const hasSocialMedia = computed(() => 
      props.mysmartcard?.socialMedias?.length > 0
    );

    const socialLinks = computed(() => {
      if (!props.mysmartcard?.socialMedias?.length) return [];
      
      return props.mysmartcard.socialMedias
        .filter(media => SOCIAL_MEDIA_CONFIG[media.label])
        .map(({ label, userName }) => ({
          icon: SOCIAL_MEDIA_CONFIG[label].icon,
          url: userName,
          label
        }));
    });

    const downloadButtonText = computed(() =>
      isDownloading.value ? 'Downloading...' : 'Download My Contact'
    );

    const handleImageError = (e) => {
      imageLoadError.value = true;
      myphotoUrl.value = defaultImage;
      e.target.src = defaultImage;
    };

    const handleDownload = async () => {
      if (isDownloading.value) return;

      try {
        isDownloading.value = true;

        // Validate required data
        if (!props.mysmartcard || !fullName.value) {
          throw new Error('Missing required contact information');
        }

        // Ensure we have either the photo data or default image
        const photoData = myphotoUrl.value === defaultImage ? null : myphotoUrl.value;
        
        // Create vCard with photo if available
        const vCardData = await toVCard(
          props.mysmartcard,
          photoData,
          { 
            withPhoto: Boolean(photoData),
            encoding: 'base64' 
          }
        );
        
        // Create and validate blob
        const blob = new Blob([vCardData], { type: "text/vcard;charset=utf-8" });
        if (blob.size === 0) {
          throw new Error('Generated vCard is empty');
        }

        // Create download link
        const url = URL.createObjectURL(blob);
        const filename = `${fullName.value.replace(/[^a-z0-9]/gi, '_')}_contact.vcf`;

        // Create and configure link element
        const link = document.createElement('a');
        link.style.display = 'none';
        link.href = url;
        link.download = filename;
        
        // Append, click, and cleanup
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        
        // Cleanup URL object
        setTimeout(() => URL.revokeObjectURL(url), 100);

        // Show the contact sheet after successful download
        showContactSheet.value = true;

      } catch (error) {
        console.error('Download failed:', error.message);
        throw new Error(`Failed to download contact: ${error.message}`);
      } finally {
        isDownloading.value = false;
      }
    };

    const handleContactSubmitSuccess = (contactData) => {
      console.log('Contact submitted successfully:', contactData);
      // You could show a success message here
    };

    const handleContactSubmitError = (error) => {
      console.error('Error submitting contact:', error);
      // You could show an error message here
    };

    // Get current URL on mount
    onMounted(() => {
      currentUrl.value = window.location.href;
    });

    const toggleQR = () => {
      showQR.value = !showQR.value;
    };

    // Cleanup
    onUnmounted(() => {
      myphotoByte.value = null;
      myphotoUrl.value = null;
    });

    return {
      fullName,
      hasOrganization,
      hasSocialMedia,
      socialLinks,
      handleDownload,
      isDownloading,
      myphotoUrl,
      downloadButtonText,
      displayPhotoUrl,
      altText,
      handleImageError,
      imageLoadError,
      showQR,
      currentUrl,
      toggleQR,
      showContactSheet,
      handleContactSubmitSuccess,
      handleContactSubmitError,
    };
  },
};
</script>

<style src="@/assets/styles/shared-styles.css"></style>

<style scoped>
.button-group {
  display: flex;
  gap: 1rem;
  justify-content: center;
}

.btn {
  display: flex;
  align-items: center;
  padding: 0.5rem 1rem;
  border-radius: 0.5rem;
  border: 1px solid #ddd;
  background: white;
  cursor: pointer;
  transition: all 0.2s;
}

.btn:hover {
  background: #f5f5f5;
}

.qr-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  cursor: pointer;
}

.qr-modal-content {
  background: white;
  padding: 2rem;
  border-radius: 1rem;
  position: relative;
  text-align: center;
  max-width: 90%;
  width: 300px;
  cursor: default;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.close-btn {
  position: absolute;
  top: 1rem;
  right: 1rem;
  background: none;
  border: none;
  cursor: pointer;
  padding: 0.5rem;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background-color 0.2s;
  color: #666;
}

.close-btn:hover {
  background-color: rgba(0, 0, 0, 0.1);
  color: #333;
}

.qr-code {
  margin: 1rem 0;
  max-width: 200px;
  height: auto;
}

.qr-instructions {
  font-size: 0.9rem;
  color: #666;
  margin-top: 1rem;
}
</style>
