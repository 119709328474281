<template>

  <div class="container" v-if="mysmartcard">
  <ProfileCard :mysmartcard="mysmartcard" />
  <ContactInfo :mysmartcard="mysmartcard" />
  <VideoPage :mysmartcard="mysmartcard" />
    <LinksPage :mysmartcard="mysmartcard" />
  </div>
  <div v-else>
    <LoadingPage />
  </div>
</template>

<script setup>
import ProfileCard from "@/components/ProfileCard.vue";
import LoadingPage from "@/components/LoadingPage.vue";
import ContactInfo from "@/components/ContactInfo.vue";
import VideoPage from '@/components/VideosPage.vue';
import LinksPage from "@/components/LinksPage.vue";
import { ref, watchEffect } from "vue";
import { auth, contactDocument } from "@/lib/firebase";
import { signInAnonymously } from "firebase/auth";
import { where, query, getDocs } from "firebase/firestore";

// Props
const props = defineProps({
  shareid: {
    type: String,
    required: false,
    default: null
  },
});

// State
const userCredential = ref(null);
const idDoc = ref(null);
const mysmartcard = ref(null);

// Separate authentication logic
const handleAuthentication = async () => {
  try {
    if (!userCredential.value) {
      userCredential.value = await signInAnonymously(auth);
    }
    return userCredential.value;
  } catch (error) {
    console.error("Authentication failed:", error);
    return null;
  }
};

// Separate query creation logic
const createContactQuery = (shareId) => {
  if (!shareId) return null;
  return query(contactDocument, where("_AppShare.cardIdShare", "==", shareId));
};

// Watch for changes and fetch data
watchEffect(() => {
  (async () => {
    try {
      const credential = await handleAuthentication();

      if (!credential) {
        console.error("No valid credentials");
        return;
      }

      // Only update if shareid changed
      if (idDoc.value !== props.shareid) {
        idDoc.value = props.shareid;
        if (idDoc.value) {
          const querySnapshot = await getDocs(createContactQuery(idDoc.value));
          const firstDoc = querySnapshot.docs[0];
          if (firstDoc) {
            mysmartcard.value = firstDoc.data();
          }
        }
      }
    } catch (error) {
      console.error("Error in watch effect:", error);
    }
  })();
}, {
  flush: 'post',
  debounce: 300
});
</script>

<style src="@/assets/styles/shared-styles.css"></style>
