import { createApp } from "vue";
import App from "./App.vue";
import router from "@/router";
import app from "@/lib/firebase";
import PrimeVue from 'primevue/config';
import Aura from '@primevue/themes/aura';

// Initialize Firebase app
app;

const vueApp = createApp(App);

// Use plugins
vueApp.use(router);
vueApp.use(PrimeVue, {
  theme: {
    preset: Aura
  }
});

// Mount the app
vueApp.mount("#app");
