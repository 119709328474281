<template>
  <div class="container">
    <router-view :shareid="shareid" />
  </div>
</template>

<script setup>
import { ref, watchEffect } from "vue";
import { useRouter } from "vue-router";
import { where, query, getDocs } from "firebase/firestore";
import { ref as storageRef, getDownloadURL } from "firebase/storage";
import { contactDocument, storage } from '@/lib/firebase';
import { STORAGE_PATHS } from '@/lib/constants';

// State management
const router = useRouter();
const shareid = ref(null);
const mysmartcard = ref(null);
const error = ref(null);

// Cache for photo URLs to improve performance
const photoUrlCache = new Map();

/**
 * Fetches and caches photo URLs
 * @param {string} photoUrl - The photo URL to fetch
 * @returns {Promise<string>} The downloaded URL
 */
const fetchPhotoUrl = async (photoUrl) => {
  if (photoUrlCache.has(photoUrl)) {
    return photoUrlCache.get(photoUrl);
  }

  const imageRef = storageRef(storage, `${STORAGE_PATHS.USER_IMAGES}/${photoUrl}`);
  try {
    const url = await getDownloadURL(imageRef);
    photoUrlCache.set(photoUrl, url);
    return url;
  } catch (err) {
    console.error("Error fetching image URL:", err);
    return '';
  }
};

/**
 * Fetches smart card data based on card ID
 * @param {string} cardId - The card ID to fetch
 */
const fetchSmartCardData = async (cardId) => {
  const q = query(contactDocument, where("_AppShare.cardIdShare", "==", cardId));
  
  try {
    const querySnapshot = await getDocs(q);
    const firstDoc = querySnapshot.docs[0];
    
    if (firstDoc) {
      const data = firstDoc.data();
      mysmartcard.value = {
        ...data,
        SmartContent: data.SmartContent || [],
      };
    }
  } catch (err) {
    error.value = err;
    console.error("Error fetching data:", err);
  }
};

// Initialize routing and data fetching
watchEffect(async () => {
  try {
    await router.isReady();
    shareid.value = router.currentRoute.value.params.Ad;
    
    if (shareid.value) {
      await fetchSmartCardData(shareid.value);
    }
  } catch (err) {
    error.value = err;
    console.error("Initialization error:", err);
  }
});
</script>

<style src="@/assets/styles/shared-styles.css"></style>