<template>
  <div v-if="show" class="bottom-sheet-overlay" @click.self="closeSheet">
    <div class="bottom-sheet">
      <div class="bottom-sheet-content">
        <div class="header-section">
          <h2>Share Your Contact Information</h2>
          <p class="subtitle">
            Would you like to share your contact information with {{ recipientName }}? 
          They will be able to add you to their contacts.
          </p>
        </div>

        <!-- Step indicators -->
        <div class="step-indicators">
          <div 
            v-for="step in 3" 
            :key="step"
            :class="['step-dot', { active: currentStep === step }]"
          ></div>
        </div>

        <form @submit.prevent="handleSubmit" class="contact-form">
          <!-- Step 1: Name -->
          <div v-if="currentStep === 1" class="form-step">
            <div class="form-row">
              <div class="form-group">
                <label for="firstName">First name*</label>
                <input 
                  id="firstName"
                  v-model="formData.firstName"
                  type="text"
                  required
                  placeholder="First name"
                  :class="['form-input', { 'error': formErrors.firstName }]"
                />
                <span v-if="formErrors.firstName" class="error-message">{{ formErrors.firstName }}</span>
              </div>

              <div class="form-group">
                <label for="lastName">Last name*</label>
                <input 
                  id="lastName"
                  v-model="formData.lastName"
                  type="text"
                  required
                  placeholder="Last name"
                  :class="['form-input', { 'error': formErrors.lastName }]"
                />
                <span v-if="formErrors.lastName" class="error-message">{{ formErrors.lastName }}</span>
              </div>
            </div>
          </div>

          <!-- Step 2: Contact -->
          <div v-if="currentStep === 2" class="form-step">
            <div class="form-group">
              <label for="email">Work email*</label>
              <input 
                id="email"
                v-model="formData.email"
                type="email"
                required
                placeholder="you@company.com"
                :class="['form-input', { 'error': formErrors.email }]"
              />
              <span v-if="formErrors.email" class="error-message">{{ formErrors.email }}</span>
            </div>

            <div class="form-group">
              <label for="phone">Work phone*</label>
              <input 
                id="phone"
                v-model="formData.phone"
                type="tel"
                required
                placeholder="+1 (555) 555-5555"
                :class="['form-input', { 'error': formErrors.phone }]"
              />
              <span v-if="formErrors.phone" class="error-message">{{ formErrors.phone }}</span>
            </div>
          </div>

          <!-- Step 3: Company -->
          <div v-if="currentStep === 3" class="form-step">
            <div class="form-group">
              <label for="company">Company name</label>
              <input 
                id="company"
                v-model="formData.company"
                type="text"
                placeholder="Enter your company name"
                class="form-input"
              />
            </div>

            <div class="form-group">
              <label for="title">Job title</label>
              <input 
                id="title"
                v-model="formData.title"
                type="text"
                placeholder="Enter your job title"
                class="form-input"
              />
            </div>
          </div>

          <div class="form-actions">
            <button 
              type="button"
              class="skip-btn"
              @click="closeSheet"
            >
              Skip for now
            </button>
            
            <button 
              v-if="currentStep > 1"
              type="button"
              class="back-btn"
              @click="previousStep"
            >
              Back
            </button>

            <button 
              v-if="currentStep < 3"
              type="button" 
              class="next-btn"
              @click="nextStep"
            >
              Next
            </button>

            <button 
              v-if="currentStep === 3"
              type="submit" 
              class="submit-btn" 
              :disabled="isSubmitting"
            >
              <Icon 
                :icon="isSubmitting ? 'line-md:loading-twotone-loop' : 'mdi:send'" 
                width="20" 
                height="20" 
              />
              {{ isSubmitting ? 'Sending...' : 'Send' }}
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, watch } from 'vue';
import { Icon } from '@iconify/vue';
import { collection, addDoc, query, where, getDocs } from 'firebase/firestore';
import { db, contactDocument, sendNotification } from '@/lib/firebase';
import { FIREBASE_COLLECTIONS, FIREBASE_SUBCOLLECTIONS } from '@/lib/constants';
import '@/assets/styles/ContactBottomSheet.css';

// Props
const props = defineProps({
  show: {
    type: Boolean,
    required: true
  },
  recipientName: {
    type: String,
    required: true
  },
  cardShareId: {
    type: String,
    required: true
  },
  fcmToken: {
    type: String,
    required: false,
    default: null
  }
});

// Emits
const emit = defineEmits(['close', 'submit-success', 'submit-error']);

// State
const currentStep = ref(1);
const isSubmitting = ref(false);
const formData = ref({
  firstName: '',
  lastName: '',
  email: '',
  phone: '',
  company: '',
  title: '',
  timestamp: new Date(),
});

const formErrors = ref({
  firstName: '',
  lastName: '',
  email: '',
  phone: ''
});

// Functions
const clearErrors = () => {
  formErrors.value = {
    firstName: '',
    lastName: '',
    email: '',
    phone: ''
  };
};

const formatPhoneNumber = (phone) => {
  if (!phone) return '';
  let formatted = phone.replace(/[^\d+]/g, '');
  if (formatted.includes('+')) {
    formatted = '+' + formatted.replace(/\+/g, '');
  }
  return formatted.slice(0, 16);
};

const validatePhoneNumber = (phone) => {
  const phoneRegex = /^\+?\d{8,15}$/;
  return phoneRegex.test(phone);
};

// Watch phone number changes
watch(() => formData.value.phone, (newValue) => {
  if (newValue && newValue !== formatPhoneNumber(newValue)) {
    formData.value.phone = formatPhoneNumber(newValue);
  }
});

const nextStep = () => {
  clearErrors();
  
  if (currentStep.value === 1) {
    if (!formData.value.firstName.trim()) {
      formErrors.value.firstName = 'First name is required';
      return;
    }
    if (!formData.value.lastName.trim()) {
      formErrors.value.lastName = 'Last name is required';
      return;
    }
  }
  
  if (currentStep.value === 2) {
    if (!formData.value.email.trim()) {
      formErrors.value.email = 'Email is required';
      return;
    }
    if (!formData.value.phone.trim()) {
      formErrors.value.phone = 'Phone number is required';
      return;
    }
    
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(formData.value.email)) {
      formErrors.value.email = 'Please enter a valid email address';
      return;
    }

    if (!validatePhoneNumber(formData.value.phone)) {
      formErrors.value.phone = 'Please enter a valid phone number (minimum 8 digits)';
      return;
    }
  }

  if (currentStep.value < 3) {
    currentStep.value++;
  }
};

const previousStep = () => {
  if (currentStep.value > 1) {
    currentStep.value--;
  }
};

const closeSheet = () => {
  currentStep.value = 1;
  emit('close');
};

const getDocumentId = async (cardShareId) => {
  try {
    const q = query(
      contactDocument,
      where('_AppShare.cardIdShare', '==', cardShareId)
    );

    const querySnapshot = await getDocs(q);
    if (querySnapshot.empty) {
      throw new Error('Card not found');
    }

    return querySnapshot.docs[0].id;
  } catch (error) {
    console.error('Error getting document ID:', error);
    throw error;
  }
};

const handleSubmit = async () => {
  if (isSubmitting.value) return;

  clearErrors();

  // Validation checks
  if (!formData.value.firstName.trim()) {
    formErrors.value.firstName = 'First name is required';
    currentStep.value = 1;
    return;
  }

  if (!formData.value.lastName.trim()) {
    formErrors.value.lastName = 'Last name is required';
    currentStep.value = 1;
    return;
  }

  if (!formData.value.email.trim()) {
    formErrors.value.email = 'Email is required';
    currentStep.value = 2;
    return;
  }

  if (!formData.value.phone.trim()) {
    formErrors.value.phone = 'Phone number is required';
    currentStep.value = 2;
    return;
  }

  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  if (!emailRegex.test(formData.value.email)) {
    formErrors.value.email = 'Please enter a valid email address';
    currentStep.value = 2;
    return;
  }

  if (!validatePhoneNumber(formData.value.phone)) {
    formErrors.value.phone = 'Please enter a valid phone number (minimum 8 digits)';
    currentStep.value = 2;
    return;
  }

  try {
    isSubmitting.value = true;

    const documentId = await getDocumentId(props.cardShareId);
    
    const contactData = {
      ...formData.value,
      timestamp: new Date(),
      cardShareId: props.cardShareId,
      status: 'pending'
    };

    const contactsCollection = collection(db, `${FIREBASE_COLLECTIONS.SMART_CARDS}/${documentId}/${FIREBASE_SUBCOLLECTIONS.RECEIVED_CONTACTS}`);
    const docRef = await addDoc(contactsCollection, contactData);

    if (props.fcmToken) {
      // Validate FCM token format
      if (typeof props.fcmToken !== 'string' || !props.fcmToken.trim()) {
        console.error('Invalid FCM token format:', props.fcmToken);
        throw new Error('Invalid FCM token format');
      }

      const token = props.fcmToken.trim();
      console.log('FCM Token validation:', {
        token: token,
        length: token.length,
        isString: typeof token === 'string'
      });

      try {
        const notificationData = {
          token: token,
          title: 'New Contact Request',
          body: `${formData.value.firstName} ${formData.value.lastName} wants to share their contact information with you.`,
          data: {
            type: 'contact_request',
            contactId: docRef.id,
            documentId: documentId
          }
        };

        console.log('Sending notification with data:', {
          token: `${token.substring(0, 10)}...${token.substring(token.length - 10)}`,
          title: notificationData.title,
          body: notificationData.body,
          data: notificationData.data
        });

        // Send notification using Cloud Functions
        const result = await sendNotification(notificationData);
        console.log('Notification result:', result);
      } catch (notificationError) {
        console.error('Error sending notification:', notificationError);
        // Continue with form submission even if notification fails
      }
    } else {
      console.log('No FCM token available, skipping notification');
    }

    emit('submit-success', { ...contactData, id: docRef.id });
    
    // Reset form
    formData.value = {
      firstName: '',
      lastName: '',
      email: '',
      phone: '',
      company: '',
      title: '',
      timestamp: new Date()
    };
    
    closeSheet();
  } catch (error) {
    console.error('Error submitting contact:', error);
    emit('submit-error', error.message);
  } finally {
    isSubmitting.value = false;
  }
};
</script>

<style src="@/assets/styles/ContactBottomSheet.css"></style> 