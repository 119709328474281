import { initializeApp } from 'firebase/app';
import { getFirestore, collection } from 'firebase/firestore';
import { getStorage } from 'firebase/storage';
import { getAuth } from 'firebase/auth';
import { getAnalytics } from 'firebase/analytics';
import { getFunctions, httpsCallable } from 'firebase/functions';
import { FIREBASE_CONFIG, FIREBASE_COLLECTIONS } from '@/lib/constants';

// Initialize Firebase app
const app = initializeApp(FIREBASE_CONFIG);

// Initialize Firebase services
export const db = getFirestore(app);
export const auth = getAuth(app);
export const storage = getStorage(app);
export const analytics = getAnalytics(app);
export const functions = getFunctions(app);

// Initialize commonly used collections
export const contactDocument = collection(db, FIREBASE_COLLECTIONS.SMART_CARDS);

// Initialize Cloud Functions
export const sendNotification = httpsCallable(functions, 'sendNotification');

// Export the app instance as default
export default app; 