<template>
  <div class="video-card" v-if="hasLinks || isLoading">
    <div class="heading">
      <h2><span class="yellow">My</span> <span class="black">Links</span></h2>
    </div>
    
    <div class="links-grid">
      <div v-if="isLoading" class="loading-container">
        <div class="loading-spinner"></div>
      </div>
      
      <a 
        v-else
        v-for="(link, index) in linkPreviews" 
        :key="index"
        :href="link.url" 
        target="_blank" 
        rel="noopener noreferrer" 
        class="link-card-wrapper"
      >
        <Card class="link-card">
          <template #header>
            <div class="link-header" :class="{ 'favicon-only': link.usesFavicon }">
              <img 
                :src="link.image" 
                :alt="link.title"
                :class="['link-image', { 'favicon': link.usesFavicon }]"
                @error="handleImageError($event, link)"
                loading="lazy"
              />
            </div>
          </template>
          
          <template #title>
            <div class="link-title">
              {{ link.title }}
            </div>
          </template>
          
          <template #content>
            <div class="link-content">
              <div class="link-domain">{{ link.domain }}</div>
              <p v-if="link.description" class="link-description">
                {{ link.description }}
              </p>
            </div>
          </template>
        </Card>
      </a>
    </div>
  </div>
</template>

<script setup>
import { computed, ref, watchEffect } from "vue";
import Card from "primevue/card";
import { fetchMetadata } from "@/composables/getLinkInfo";

// Props
const props = defineProps({
  mysmartcard: {
    type: Object,
    required: true,
  },
});

// State
const linkPreviews = ref([]);
const isLoading = ref(false);

// Computed
const hasLinks = computed(() => 
  linkPreviews.value && linkPreviews.value.length > 0
);

// Methods
const handleImageError = (event, link) => {
  event.target.src = link.favicon;
  link.usesFavicon = true;
};

// Watch for changes in smart card content
watchEffect(async () => {
  if (!props.mysmartcard?.SmartContent) return;

  const linkContents = props.mysmartcard.SmartContent.filter(
    content => content.contentType === "linkContent"
  );

  if (linkContents.length > 0) {
    try {
      isLoading.value = true;
      linkPreviews.value = await fetchMetadata(linkContents);
    } catch (error) {
      console.error('Error fetching link previews:', error);
    } finally {
      isLoading.value = false;
    }
  }
});
</script>

<style src="@/assets/styles/links-page.css" scoped></style>
